// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import OTPInput from "../../mobileComponents/otpInput";
import OtpTimer from "../otpTimer";
// Icons, Images etc.
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { loaderOperations } from "../../state/features/loader";

interface IMobileVerificationProps {
  proceedToCheckout: boolean;
  onClose: any;
  hideModal: any;
  showLoader: any;
  setShowEmailOtp: (value: boolean) => void;
  showEmailOtp: boolean;
  setPageType: (value: string) => void;
  userPayload: any;
  company: any;
  smsDisabled: boolean;
  signIn: (data: any, token: string) => void;
  sendOtp: (token: string) => void;
  resendOtp: (token: string) => void;
  sendOtpViaEmail: (payload: any, token: string) => void;
  resendOtpViaEmail: (payload: any, token: string) => void;
}

function MobileVerification(props: IMobileVerificationProps) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("" as null | string);
  const [redirectURL, setRedirectURL] = useState("");
  const [showOtp, setShowOtp] = useState(true);
  const [otpTimeout, setOtpTimeout] = useState(false);
  const [newOtpRequested, setNewOtpRequested] = useState(false);

  const csrfToken = document.querySelector('[name=csrf-token]').content;

  useEffect(() => {
    sendOtp();
  }, []);

  const sendOtp = () => {
    const handlePostOtpRequest = (response: any) => {
      if (response.error) {
        handleClose();
      }
    };
    if (!props.showEmailOtp) {
      props.sendOtp(csrfToken).then(handlePostOtpRequest);
    } else {
      props
        .sendOtpViaEmail({ email: props.userPayload.email }, csrfToken)
        .then(handlePostOtpRequest);
    }
  };

  const resendOtp = () => {
    setOtpError("");
    setShowOtp(false);
    props.hideModal(true);
    const handlePostOtpRequest = (response: any) => {
      if (response.error) {
        handleClose();
      } else {
        setShowOtp(true);
        props.hideModal(false);
        setOtpTimeout(false);
      }
    };
    if (!props.showEmailOtp) {
      props.resendOtp(csrfToken).then((response: any) => {
        if (!response.error) {
          setNewOtpRequested(true);
        }
        handlePostOtpRequest(response);
      });
    } else {
      props
        .resendOtpViaEmail({ email: props.userPayload.email }, csrfToken)
        .then((response: any) => {
          if (!response.error) {
            setNewOtpRequested(true);
          }
          handlePostOtpRequest(response);
        });
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  const submitOtp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.hideModal(true);
    let userData = { otp: otp };
    if (props.showEmailOtp && props.userPayload.email) {
      userData['email'] = props.userPayload.email;
    }

    props.signIn(userData, csrfToken).then((response: any) => {
      if (!response.error) {
        onUserActionComplete();
      } else if (response.error.response.data.message) {
        setOtpError("Incorrect OTP");
        props.hideModal(false);
      }
    });
  };

  const onUserActionComplete = () => {
    localStorage.removeItem("userEmail");
    if (props.proceedToCheckout) {
      props.showLoader();
      setRedirectURL("/checkout");
    } else {
      handleClose();
    }
  };

  const handleOTPInput = (otp: any) => {
    setOtpError("");
    setOtp(otp);
  };

  const switchToEmailVerification = () => {
    const handlePostOtpRequest = (response: any) => {
      if (response.error) {
        handleClose();
      }
    };

    props.setShowEmailOtp(true);

    if (props.userPayload.email) {
      props
        .sendOtpViaEmail({ email: props.userPayload.email }, csrfToken)
        .then(handlePostOtpRequest);
    } else {
      props.setPageType("sign-in");
    }
  }

  return (
    <React.Fragment>
      {redirectURL ? (
        <Redirect to={redirectURL} />
      ) : (
        <div className="user-login px-3 py-5">
          <h1 className="header">
            <FormattedMessage
              id="registration.otp.enter_the_otp_code"
              defaultMessage="Enter the OTP Code"
            />
          </h1>
          <div>
            <h3 className="mt-2 mb-3 text-center">
              {!props.showEmailOtp ? (
                <FormattedMessage
                  id="registration.otp.enter_code_sent_to_phone"
                  defaultMessage="Please enter the code we have sent to <pTag>{mobileNumber}</pTag>."
                  values={{
                    mobileNumber: `+${props.userPayload.dialing_code} ${props.userPayload.phone_number}`,
                    pTag: (mobileNumber: any) => (
                      <p className="font-weight-bold">{mobileNumber}</p>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="registration.otp.enter_code_sent_to_email"
                  defaultMessage="Please enter code we sent to <pTag>{email}</pTag>."
                  values={{
                    email: `${props.userPayload.email}`,
                    pTag: (email: any) => (
                      <p className="font-weight-bold">{email}</p>
                    ),
                  }}
                />
              )}
            </h3>
            {newOtpRequested && (
              <p className="otp-resent-info">
                <FormattedMessage
                  id="registrations.otp.resend_requested"
                  defaultMessage="A new OTP has been sent"
                />
              </p>
            )}
            <form className="text-center" onSubmit={submitOtp}>
              {showOtp ? (
                <OTPInput
                  dir="ltr"
                  autoFocus
                  length={4}
                  type="number"
                  className="otpContainer"
                  inputClassName="otpInput form-control"
                  onChangeOTP={(otp: any) => handleOTPInput(otp)}
                />
              ) : null}
              {otpError ? (
                <div className="input-label text-center text-danger">
                  <FormattedMessage
                    id="registration.otp.incorrect_otp"
                    defaultMessage="Incorrect OTP."
                  />
                </div>
              ) : null}
              <button className="btn button btn-primary w-100 py-2 mt-4 mb-3">
                <FormattedMessage
                  id="global.continue"
                  defaultMessage="Continue"
                />
                <span className="ml-3">
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </span>
              </button>
              <p className="other-actions otp-resend">
                <FormattedMessage
                  id="registration.otp.receive_question"
                  defaultMessage="Didn't receive the OTP?"
                />
              </p>
              {!otpTimeout ? (
                <p className="other-actions otp-wait">
                  <OtpTimer setOtpTimeout={setOtpTimeout} />
                </p>
              ) : (
                <p
                  className="other-actions otp-resend-action py-2"
                  onClick={resendOtp}
                >
                  <FormattedMessage
                    id="registrations.otp.resend_button"
                    defaultMessage="Resend"
                  />
                </p>
              )}
            </form>
          </div>
        </div>
      )}
      {"email" in props.company.otp_settings &&
      props.company.otp_settings.email &&
      !props.showEmailOtp ? (
        <p
          className="other-actions change-otp-mode desktop"
          onClick={() => switchToEmailVerification()}
        >
          <FormattedMessage
            id="registration.otp.sent_otp_to_my_email"
            defaultMessage="Send OTP to my email"
          />
        </p>
      ) : !props.smsDisabled && props.showEmailOtp ? (
        <p
          className="other-actions change-otp-mode desktop"
          onClick={() => {
            props.setShowEmailOtp(false);
            props.setPageType("sign-in");
          }}
        >
          <FormattedMessage
            id="registrations.otp.receive_otp_via_sms"
            defaultMessage="Receive OTP via SMS"
          />
        </p>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let smsDisabled = company?.sms_disabled || false;

  return { 
    company,
    smsDisabled
   };
};

const mapDispatchToProps = {
  sendOtp: userOperations.sendOtp,
  resendOtp: userOperations.resendOtp,
  signIn: userOperations.signIn,
  showLoader: loaderOperations.showLoader,
  sendOtpViaEmail: userOperations.sendOtpViaEmail,
  resendOtpViaEmail: userOperations.resendOtpViaEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);
