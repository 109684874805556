import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import EmailVerification from '../emailVerificationMobile';
import { userOperations } from '../../../state/features/user';

interface EmailFieldMobileProps {
  email: string | undefined;
  setUser: (user: { email: string }) => void;
}

const EmailFieldMobile: React.FC<EmailFieldMobileProps> = ({ email, setUser }) => {
  const [tempEmail, setTempEmail] = useState<string>(email || ''); 
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hidePopup, setHidePopup] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    setTempEmail(email || '');
  }, [email]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempEmail(e.target.value);
    setEmailError(null);
  };

  const handleResetEmail = () => {
    setIsEditingEmail(true);
    setTempEmail(email || '');
  };

  const handleSave = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(tempEmail);

    if (!isValid) {
      setEmailError('invalid');
      return;
    }

    if (tempEmail === email) {
      setEmailError('same');
    } else {
      setEmailError(null);
      setShowModal(true);
    }
  };

  const handleOtpSuccess = () => {
    setIsEditingEmail(false);
    setShowModal(false);
    setHidePopup(false);
    setUser({ email: tempEmail });
  };

  const handleCancel = () => {
    setIsEditingEmail(false);
    setTempEmail(email || '');
    setEmailError(null);
  };

  const handleClose = () => {
    setShowModal(false);
    setHidePopup(false);
  };

  const errorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'invalid':
        return (
          <FormattedMessage
            id="my_account.invalid_email"
            defaultMessage="Invalid email address"
          />
        );
      case 'same':
        return (
          <FormattedMessage
            id="my_account.same_email"
            defaultMessage="This email is already in use."
          />
        );
      default:
        return (
          <FormattedMessage
            id="my_account.something_went_wrong_try_again"
            defaultMessage="Something went wrong. Please try again later."
          />
        );
    }
  };

  return (
    <div className="input-wrapper">
      <label>
        <FormattedMessage id="my_account.email_address" defaultMessage="Email Address:" />
      </label>
      <div className="email-container">
        <FormattedMessage
          id="my_account.email.placeholder"
          defaultMessage="example@domain.com"
        >
          {(placeholder) => (
            <input
              type="email"
              value={tempEmail}
              onChange={handleEmailChange}
              disabled={!isEditingEmail}
              placeholder={placeholder.toString()}
            />
          )}
        </FormattedMessage>

        {emailError && (
          <p className="error-message">
            {errorMessage(emailError)}
          </p>
        )}
        {!isEditingEmail && (
          <button className="reset-btn" onClick={handleResetEmail}>
            <FormattedMessage id="my_account.update" defaultMessage="Update" />
          </button>
        )}
      </div>

      {isEditingEmail && (
        <div className="button-group">
          <button className="cancel-btn" onClick={handleCancel}>
            <FormattedMessage id="my_account.cancel" defaultMessage="Cancel" />
          </button>
          <button 
            className={`save-btn ${!tempEmail || emailError ? 'disabled' : ''}`} 
            onClick={handleSave} 
            disabled={!tempEmail || emailError}
          >
            <FormattedMessage id="my_account.save" defaultMessage="Save" />
          </button>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Body>
          <EmailVerification
            userPayload={{ email: tempEmail }}
            onSuccess={handleOtpSuccess}
            onClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  email: state.user.email,
});

const mapDispatchToProps = {
  setUser: userOperations.setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailFieldMobile);
