export const SET_USER = "user/SET_USER";
export const UPDATE_PROFILE = "user/UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "user/UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "user/UPDATE_PROFILE_FAIL";
export const FETCH_ORDER_IN_PROGRESS = "user/FETCH_ORDER_IN_PROGRESS";
export const FETCH_ORDER_IN_PROGRESS_SUCCESS = "user/FETCH_ORDER_IN_PROGRESS_SUCCESS";
export const FETCH_ORDER_IN_PROGRESS_FAIL = "user/FETCH_ORDER_IN_PROGRESS_FAIL";
export const SIGN_OUT = "user/SIGN_OUT";
export const SIGN_OUT_SUCCESS = "user/SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAIL = "user/SIGN_OUT_FAIL";
export const VERIFY_USER = "user/VERIFY_USER";
export const VERIFY_USER_SUCCESS = "user/VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "user/VERIFY_USER_FAIL";
export const SIGN_UP = "user/SIGN_UP";
export const SIGN_UP_SUCCESS = "user/SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "user/SIGN_UP_FAIL";
export const SIGN_IN = "user/SIGN_IN";
export const SIGN_IN_SUCCESS = "user/SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "user/SIGN_IN_FAIL";
export const SEND_OTP = "user/SEND_OTP";
export const SEND_OTP_SUCCESS = "user/SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "user/SEND_OTP_FAIL";
export const RESEND_OTP = "user/RESEND_OTP";
export const RESEND_OTP_SUCCESS = "user/RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "user/RESEND_OTP_FAIL";
export const SEND_OTP_FOR_EMAIL_RESET = "user/SEND_OTP_FOR_EMAIL_RESET";
export const SEND_OTP_FOR_EMAIL_RESET_SUCCESS = "user/SEND_OTP_FOR_EMAIL_RESET_SUCCESS";
export const SEND_OTP_FOR_EMAIL_RESET_FAIL = "user/SEND_OTP_FOR_EMAIL_RESET_FAIL";
export const RESEND_OTP_FOR_EMAIL_RESET = "user/RESEND_OTP_FOR_EMAIL_RESET";
export const RESEND_OTP_FOR_EMAIL_RESET_SUCCESS = "user/RESEND_OTP_FOR_EMAIL_RESET_SUCCESS";
export const RESEND_OTP_FOR_EMAIL_RESET_FAIL = "user/RESEND_OTP_FOR_EMAIL_RESET_FAIL";
export const RESET_EMAIL = "user/RESET_EMAIL";
