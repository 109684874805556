import {
  FIXED_SURCHARGE,
  ORDER_TYPE_DELIVERY,
} from "../helpers/constants";
import {
  formatDecimal,
} from "../helpers/itemCalculations";

const orderSurcharges = (
  surcharges: any,
  taxes: any,
  orderType: any,
  area: any,
  deliveryChargeId: any,
) => {
  surcharges = surcharges.filter((charge: any) => {
    if (orderType == ORDER_TYPE_DELIVERY) {
      return charge.applicable_on_weborder;
    } else {
      return charge.applicable_on_takeaway && charge.id != deliveryChargeId;
    }
  });
  return (
    surcharges.map((surcharge: any) => {
      let surchargeValue;
      let isDeliveryCharge = surcharge.id == deliveryChargeId;
      if (isDeliveryCharge) {
        surchargeValue = area.delivery_charge == null ? surcharge.value : area.delivery_charge;
      } else {
        surchargeValue = surcharge.value;
      }
      let surchargeItem = {
        id: surcharge.id,
        name: surcharge.name,
        surcharge_type: (surcharge.type == FIXED_SURCHARGE ? 2 : 1),
        rate: parseFloat(surchargeValue),
        is_taxable: (surcharge.taxes.length > 0),
        is_delivery_charge: isDeliveryCharge
      }
      if (surcharge.taxes.length) {
        let taxId = surcharge.taxes[0].taxes[0];
        let tax = taxes.find((tax: any) => { return (tax.id == taxId) })
        let surchargeTax = {
          tax_rate_id: taxId,
          tax_rate: parseFloat(tax.value)
        }
        Object.assign(surchargeItem, surchargeTax);
      }
      return surchargeItem;
    })
  );
}

const orderItems = (items: any) => {
  return (
    items.map((item: any) => {
      let itemDetails =  {
        id: item.id,
        quantity: parseFloat(item.count),
        note: '',
        free_item: false,
        modifiers: [] as any,
        name: item.name,
        unit_price: parseFloat(item.unit_price),
        sub_total: parseFloat(item.sub_total),
        image_url: item.image_url,
        translations: item.translations,
      }
      item.groups.map((group: any) => {
        group.items.map((modifier: any) => {
          if (modifier.selected) {
            let count = 1;
            if ("count" in modifier) {
              count = modifier.count;
            }
            itemDetails.modifiers.push(
              {
                id: modifier.id,
                group_id: group.id,
                quantity: count,
                name: modifier.name,
                unit_price: parseFloat(modifier.unit_price),
                sub_total: count * parseFloat(modifier.unit_price),
                items_modifer_group_item_id: modifier.modifier_id,
                translations: modifier.translations,
              }
            )
          }
        })
      });

      return itemDetails;
    })
  );
}

const baseOrderPayload = (state: any, props: any) => {
  let payload = {
    online_company_id: props.company.id,
    online_location_id: props.outlet.id,
    scheduled_at: state.scheduledTime ? state.scheduledTime : null,
    default_eta: state.deliveryEta,
    online_delivery_eta: state.minimumEtaMinutes,
    note: {
      user: state.orderNotes || "",
      meta: state.metaNotes || "",
    },
    surcharges: orderSurcharges(
      props.menu.surcharges, props.menu.taxes, props.orderType,
      props.selectedArea, props.outlet.delivery_charge_id
    ) as any,
    items: orderItems(props.cart.items) as any,
    delivery_address_id: state.selectedAddress.id,
    delivery_area: props.selectedArea?.name,
    complete_address: state.selectedAddress?.lines,
    discounts: (
      props.discount && Object.keys(props.discount).length ? (
        [{
          id: props.discount.discount_id,
          name: props.discount.discount_name,
          type: props.discount.discount_type.toLowerCase(),
          discount_type: props.discount.discount_type == "PERCENTAGE" ? 1 : 2,
          on_total: props.discount.on_total,
          rate: props.discount.discount_rate,
          amount: props.discount.discountedAmount,
        }]
      ) : []
    ),
    redeemed_loyalty: 0,
    remaining_loyalty: 0,
    earned_loyalty: 0,
    has_loyalty_data: false,
    loyalty_as_discount: props.loyaltyConfig?.is_discount || false,
    sub_total: props.cart.subTotal,
    sub_total_after_discount: props.cart.subTotalAfterDiscount,
    sub_total_tax_after_discount: props.cart.subTotalTaxAfterDiscount,
    surcharge_sub_total: props.cart.surchargeSubTotal,
    surcharges_breakdown: props.cart.surchargeBreakDown,
    tax_total: props.cart.taxTotal,
    total_amount: props.cart.total,
    order_type: props.session.order_type,
    customer_id: props.user.sapaad_customer_id,
    cartItems: props.cart.items,
    is_tax_inclusive: props.menu.is_tax_inclusive,
    pickup_mode: "",
    user_name: state.userName,
    user_email: state.userEmail,
    currency: props.company.currency,
    total_before_loyalty: props.cart.totalBeforeLoyalty
  };
  if (
    props.loyaltyConfig && props.loyaltyConfig.value_for_points &&
    props.loyalty && Object.keys(props.loyalty).length
  ) {
    payload.has_loyalty_data = true;
    payload.loyalty_value = props.loyaltyConfig.value_for_points;
    if (props.loyalty.redeemedPoints) {
      payload.redeemed_loyalty = props.loyalty.redeemedPoints;
      payload.remaining_loyalty =
        parseFloat(props.loyalty.customer_loyalty || 0) -
        parseFloat(props.loyalty.redeemedPoints);
    } else {
      payload.remaining_loyalty = parseFloat(props.loyalty.customer_loyalty || 0);
    }
  }
  if (props.orderType == "pickup") {
    payload.pickup_mode = state.pickupMode;
  } else {
    payload.source_coordinates = {
      latitude: props.outlet.latitude,
      longitude: props.outlet.longitude,
    };
    payload.destination_coordinates = {
      latitude: state.selectedAddress?.latitude,
      longitude: state.selectedAddress?.longitude,
    }
  }

  if (state.tipAmount) {
    payload.tips = state.tipAmount;
  }

  return payload;
};

const orderPayload = (state: any, props: any) => {
  let paymentMode = state.paymentTypes.find((pt: any) => {
    return pt.payment_type === state.selectedPaymentType;
  });

  let paymentDetails: any = {
    id: paymentMode.sapaad_payment_type_id,
    payment_type_id: paymentMode.sapaad_payment_type_id,
    payment_type: paymentMode.payment_type,
    amount: parseFloat(props.cart.total),
    name: state.selectedPaymentTypeTitle,
    tips: state.tipAmount,
  };

  return (
    Object.assign(baseOrderPayload(state, props), { payment: paymentDetails })
  );
}

export {
  baseOrderPayload,
  orderPayload,
  orderItems,
};
